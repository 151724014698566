// Select 2 
@import "~@ng-select/ng-select/themes/default.theme.css";

// dropdown
.dropdown-toggle {
    &:after {
        display: none;        
    }
}

.dropdown-toggle::after{
    display: none;
}

.dropzone .dz-message {
    font-size: 24px;
    min-height: 150px !important;
    border: none !important;
    background: #fff !important;
    color: #495057 !important;
  }

  // ----- Advance Ui --------//
// Rating
.stars {
    font-size: 30px;
    color:var(--bs-gray-600);
}
.star {
    position: relative;
    display: inline-block;
}
.star_fill {
    color: $gray-500;
}
.full {
    color: #b0c4de;
}
.half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color:var(--bs-gray-600);
}
.filled {
    color: #1e90ff;
}
.bad {
    color: #deb0b0;
}
.filled.bad {
    color: #ff1e1e;
}
.halfHeart {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color:red;
}

// Calendar
.flatpickr-calendar.open{
    z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked > .switch-pane > span{
    color: #fff;
}
.switch.switch-small > .switch-pane > span{
    font-size: 14px;
}

// Ck Editer
.ck-editor__editable {
    min-height: 245px !important;
}

.custom-accordion{
    .accordion-list {
        padding: 0px;
        background-color: transparent;
    }
}

.search-style .ng-arrow-wrapper .ng-arrow {
    display: none !important;
  }
  
  .search-style>.ng-select-container:after {
    content: "\f002";
    font-family: "Font Awesome 6 Pro";
    font-size: 1rem;
    top: 2px;
    position: absolute;
    right: 10px;
  }

  .ng-select-container {
    font-size: var(--bs-body-font-size) !important;
    font-weight: 400 !important;
    border: 1px solid var(--main-border-color) !important;
  }
  
  .ng-select .ng-select-container,
  .ng-select.ng-select-single .ng-select-container {
    min-height: 31px !important;
  }
  
  .ng-select.ng-select-single .ng-select-container {
    height: 31px;
  }
  
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 0px !important;
  }
  
  .ng-dropdown-panel {
    border-color: var(--main-border-color) !important;
    background-color: white !important;
  }
  
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--selected-menu-item-color) !important;
  }
  
  .ng-select.is-invalid:not(.ng-select-focused) .ng-select-container {
    border-color: #f46a6a !important;
  }

//dropzone

.dropzone>.dropzone.dz-wrapper .dz-message{
    background: var(--#{$prefix}custom-white) !important;
    color: var(--#{$prefix}body-color) !important;
}

// ck editor
.ck.ck-toolbar {
    background-color: var(--#{$prefix}light) !important;
    border: 1px solid $input-border-color !important;
  }
  .ck-reset_all :not(.ck-reset_all-excluded *),
  .ck.ck-reset_all {
    color: var(--#{$prefix}body-color) !important;
  }
  .ck.ck-editor__main > .ck-editor__editable {
    background-color: var(--#{$prefix}custom-white) !important;
    color: var(--#{$prefix}body-color) !important;
  }
  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-color: $input-border-color !important;
  }
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover,
  .ck.ck-button.ck-on,
  a.ck.ck-button.ck-on {
    background-color: var(--#{$prefix}light) !important;
  }
  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background: $input-border-color !important;
  }


// Dark Mode Css
  [data-layout-mode="dark"] {
    .auth-logo .auth-logo-light{
        display: block;
    }
    .auth-logo .auth-logo-dark{
        display: none;
    }

    // Accordion
    .accordion{
        --bs-accordion-color: #fff;
        --bs-accordion-bg: transparent;
    }
    .accordion-button{
        color: var(--bs-body-color);
    }
  } 

  .page-link{
    display: inline-table;
  }


//   vertical tab

.vertical-tab{
    ul{
        display: inline-block;
        position: relative;
        z-index: 1;
        border: 0;
        @media (max-width: 1024px) { 
            display: block;
        }
        li{
            a{
                padding: 8px 50px;

                @media (max-width: 1440mpx) { 
                    padding: 8px 30px;
                }
            }
        }
    }

    .tab-content{
        position: absolute;
        top: 0;

        @media (max-width: 1024px) { 
            position: inherit;
        }

    }
}


.ecommerce-checkout-tab{
   ul{
    display: inline-block;
   float: left;
    margin-right: 20px;
    border: 0;
    @media (max-width: 600px) { 
        display: block;
        float: inherit;
    }
   }
   li{
    a{
        padding: 1px 80px;
    }
   }
}

.product-detai-imgs{
    ul{
        border: 0;
        a{
            border: 0 !important;
            width: 130px;
            @media (max-width: 425px) { 
                width: 50px;
            }
        }
    }
}

.tooltip,.tooltip-arrow{
    position: absolute;
}