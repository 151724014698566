:root {
  --primary: #3855F5;
  --secondary: #74788d;
  --soft-secondary: #e1e1e1;
  --success: #34c38f;
  --info: #50a5f1;
  --warning: #f1b44c;
  --danger: #f46a6a;
  --light: #eff2f7;
  --dark: #343a40;
  --white: #ffffff;
  --bs-custom-white: #ffffff;
  --light-pink: #FFF6F6;
  --hover-color: #EFFBFE;
  --main-button-color: #513DCA;
  --main-button-hover-color: #604ec0;
  --main-border-color: #ced4da;
  --header-height: 65px;
  --left-menu-width: 65px;
  --bs-form-check-input-border: #ced4da;
  --bs-body-font-size: 0.7125rem;
  --bs-modal-zindex: 999;
  --bs-modal-header-padding-override: 0.5rem 1rem;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('assets/fonts/AvenirNextLTPro-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background: radial-gradient(circle, rgba(234, 252, 255, 1) 0%, rgba(240, 238, 253, 1) 67%);
}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal {
  z-index: 999 !important;
}

.modal-backdrop {
  z-index: 998 !important;
}

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

.modal-header {
  padding: var(--bs-modal-header-padding-override) !important;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.bg-white {
  background-color: #fff !important;
}

.no-border {
  border: none !important;
}

.fs-tiny {
  font-size: 0.6rem;
}

.primary-button {
  background-color: var(--main-button-color) !important;
  border-color: var(--main-button-color) !important;

  &:hover {
    background-color: var(--main-button-hover-color) !important;
  }
}

.primary-link {
  color: var(--main-button-color) !important;

  &:hover {
    text-decoration: underline;
  }
}

i.footer-insert-clear-button {
  width: 20px;
  height: 20px;
  background: #FEF0F0;
  color: #EB5858;
  padding: 4px 6px;
  border-radius: 3px;
}

i.footer-insert-add-button {
  width: 20px;
  height: 20px;
  background: #E6F2F1;
  color: var(--main-button-color);
  padding: 4px;
  border-radius: 3px;
}

input.form-control {
  display: block;
  width: 100%;
  padding: .37rem .75rem;
  font-size: var(--bs-body-font-size);
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--main-border-color);
  ;
  -webkit-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:focus {
    color: #343a40;
    background-color: #fff;
    border-color: rgb(73, 80, 87, .2);
    outline: 0;
    box-shadow: none;
  }
}


select.form-select {
  display: block;
  width: 100%;
  padding: 0.37rem 1.75rem 0.37rem 0.75rem;
  font-size: var(--bs-body-font-size);
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid var(--main-border-color);
  ;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    border-color: rgb(73, 80, 87, .2);
    outline: 0;
    box-shadow: 0 0 0 0.15rem #556ee640;
  }
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border-color: #f46a6a;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

ngx-intl-tel-input>div.iti {
  width: 100%;
}

.country-dropdown {
  font-size: var(--bs-body-font-size) !important;
}

#country-search-box {
  padding: 5px 8px;

  &:focus-visible {
    outline: none !important;
  }
}

.table-action-column {
  width: 5rem;
  text-align: right !important;
}

.mini-action-column {
  width: 5rem;
}

.small-table-column {
  width: 10rem;
  text-align: center;
}

.medium-table-column{
  width: 15rem;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28116, 120, 141, 0.55%29'/%3e%3c/svg%3e") !important;
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out;
  border: 1px solid var(--main-border-color);
  ;
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.btn-close {
  font-size: 1.3rem !important;
}

#list-search-box {
  padding-left: 2rem;
  border-radius: 20px;
}

#list-search-icon {
  font-size: 0.75rem;
  top: 18px;
}

span.list-status-column {
  border-radius: 5px;
  padding: 5px 6px;
  font-size: 0.6rem;
  background: #8D7BFF;
  color: #fff;
}

////=================primng table sort icon
sortalticon,
sortamountupalticon,
sortamountdownicon {
  svg {
    display: none !important;
  }
}

sortalticon:before {
  display: none !important;
}

sortamountupalticon:before {
  content: "\e9a2";
}

sortamountdownicon:before {
  content: "\e9a0";
}

sortalticon:before,
sortamountupalticon:before,
sortamountdownicon:before {
  font-size: 0.8rem;
  font-family: 'primeicons';
}

//============primeng table styling
.p-component {
  font-family: inherit;
  font-size: 0.8rem;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #343a40;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding: 0.4rem;
}

.p-datatable .p-datatable-thead>tr>th {
  padding: 0.7rem 0.4rem;
}

.p-datatable .p-datatable-tbody>tr:hover {
  background: #e9ecef;
}

.p-datatable .p-datatable-header {
  background: #fff;
  color: #343a40;
  border: none;
  padding: 0 0.2rem 1rem 0.2rem;
  font-weight: 700;
}

.pac-container {
  //this is a fix for google autocomplete not showing up
  z-index: 10000 !important;
}

.form-check-default .form-check-input:checked {
  background-color: var(--main-button-color);
  border-color: var(--main-button-color);
}

.wide-modal-dialog {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.wide-modal-dialog .modal-content {
  width: 100%;
  height: 100%;
}

.drawer-style-modal {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.drawer-style-modal .modal-content {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  position: fixed;
  right: 0;
  height: 100%;
  border-radius: 0;
}

.tab-content {
  background-color: #fff;
  padding: 0.5rem;
}

.nav-tabs-health-exam {
  .nav.nav-tabs {
    border: none !important;

    .nav-item {
      position: relative;
      background: #ECEDF2;

      &:not(:last-child) {
        border-right: 1px solid #CCCED3;
      }

      &:last-child {
        border-top-right-radius: 4px;
      }

      &.active {
        border-right: none;
        box-shadow: 0 -2px 9px #E9E9EB;
        margin-top: -10px;
        padding-top: 6px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        background: #fff;
        margin-left: -1px;
        z-index: 1;

        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          height: 10px;
          width: 20px;
        }

        &:after {
          right: -20px;
          border-radius: 0 0 0 10px;
          -moz-border-radius: 0 0 0 10px;
          -webkit-border-radius: 0 0 0 10px;
          -webkit-box-shadow: -10px 0 0 0 #fff;
          box-shadow: -10px 0 0 0 #fff;
        }

        &:before {
          left: -20px;
          border-radius: 0 0 10px 0;
          -moz-border-radius: 0 0 10px 0;
          -webkit-border-radius: 0 0 10px 0;
          -webkit-box-shadow: 10px 0 0 0 #fff;
          box-shadow: 10px 0 0 0 #fff;
        }
      }

      .nav-link {
        border: none;

        &:after {
          content: "";
          background: var(--main-button-color);
          height: 14px;
          position: absolute;
          width: 2px;
          left: 10px;
          transition: all 250ms ease 0s;
          transform: scale(0);
          border-radius: 10px;
          top: 15px;
        }

        &.active {
          color: var(--main-button-color);
          background-color: #fff;

          &:after {
            transform: scale(1);
          }

          span {
            color: var(--main-button-color);
            font-weight: bold;
          }
        }

        span {
          color: #5F6269;
        }
      }
    }
  }
}

.simplebar-content {
  height: 100%;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.patient-search-modal>.modal-content {
  min-height: 340px;
}

.app-header {
  popover-container {
    margin: 0 !important;
    left: 124px !important;
    top: -46px !important;
    box-shadow: 0 0 10px .1rem #556ee621;
    min-width: 300px
  }

  .popover-body {
    padding: 0 !important;
  }

  .popover-arrow.arrow {
    display: none !important;
  }
}